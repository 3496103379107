<template>
  <div class="top-nav">
    <nav class="navbar navbar-expand-md navbar-dark menuarea">
      <div :class="['container']">
        <div v-if="device === 'mobile'">
          <a :class="['navbar-brand', 'order-1', 'mobile_logo', 'col-6', 'm-0', 'p-0', 'mb-2', marketerId === ''? 'filtered_logo': '']" :href="logoURL">
            <img :class="['img-fluid', marketerId === '' ? '' : 'marketer_logo']" :src="srcLogo" alt="logo"/>
          </a>
          <div class="w-75" v-if="isEmalonMode && currentPage === 'home'">
            <SelectLanguage/>
          </div>
        </div>
        <div :class="['content-right', 'd-flex', 'order-2', 'order-md-1', (device=== 'mobile' ? 'col-5': '')]">
          <ul class="list-inline mr-auto">
            <li class="list-inline-item" v-if="device === 'mobile' && marketerId !== ''">
              <a class="navbar-brand order-1" :href="homeLink">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ lang==='he' ? $t("top-nav.domestic-tourism") : $t("top-nav.domestic-tourism-short") }}
                </button>
              </a>
            </li>
            <li :class="['list-inline-item', (device === 'mobile' ? 'mt-10': '')]" v-if="device === 'mobile' && showFellowLink">
              <a class="navbar-brand order-1" :href="fellowLink">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ lang==='he' ? $t("top-nav.vacation-country") : $t("top-nav.vacation-country-short") }}
                </button>
              </a>
            </li>
            <li :class="['list-inline-item']" v-if="device === 'desktop' && marketerId === '' && (!isOdysseySite || isFCLAgent)">
              <div id="st-trigger-effects" class="">
                <button class="btn btn-outline-secondary text-light" data-effect="st-effect-9" v-b-toggle.nav-menu>
                  <i class="fa fa-bars"></i>
                </button>
              </div>
            </li>
            <li :class="['list-inline-item']" v-if="isEmalonMode && currentPage === 'home' && device === 'desktop'">
              <SelectLanguage/>
            </li>
            <li class="list-inline-item" v-if="device === 'desktop' && (marketerId !== '' || currentPage !== 'home')">
              <a class="navbar-brand order-1" :href="homeLink">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ $t("top-nav.domestic-tourism") }}
                </button>
              </a>
            </li>
            <!--<li class="list-inline-item">
              <button class="btn btnLogin" > {{$t('top-nav.login')}} <i class="far fa-user"></i> </button>
            </li>-->
            <!-- <li class="list-inline-item">
              <button class="btn btn-outline-secondary btn-theme-onWhite">
                {{ $t("top-nav.enrollment") }}
              </button>
            </li> -->
            <li class="list-inline-item btn-topVacation" v-if="showFellowLink">
              <a class="navbar-brand order-1" :href="fellowLink">
                <button class="btn btn-outline-secondary btn-theme-onTheme">
                  {{ $t("top-nav.vacation-country") }}
                </button>
              </a>
            </li>
            <li class="list-inline-item" v-if="isOdysseySite && !isFCLAgent && !isSpecialAgent && currentPage !== 'booking-info'">
              <router-link class="navbar-brand order-1" to="/personal-area">
                <button class="btn btn-theme-onWhite">
                  {{ $t("booking-info.personal-area") }}
                </button>
              </router-link>
            </li>
            <li class="list-inline-item red" v-if="device === 'desktop' && isFCLAgent">
              <a class="navbar-brand order-1" :href="specificDocketLink" target="_blank">
                <button class="btn btnDomestic btn-theme-onWhite">
                  {{ $t("top-nav.specific-docket") }}
                </button>
              </a>
            </li>
          </ul>
        </div>
        <ul class="col-1 displayLeft mb-auto content-right" v-if="device === 'mobile' && marketerId === '' && (!isOdysseySite || isFCLAgent)">
          <li :class="['list-inline-item','displayLeft m-0']">
              <div id="st-trigger-effects" class="">
                <button class="btn btn-outline-secondary text-light" data-effect="st-effect-9" v-b-toggle.nav-menu>
                  <i class="fa fa-bars"></i>
                </button>
              </div>
            </li>
        </ul>
        <a class="navbar-brand order-1" :href="logoURL" v-if="device === 'desktop'">
          <img :class="['img-fluid', marketerId === '' ? '' : 'marketer_logo']" :src="srcLogo" alt="logo"/>
        </a>
      </div>
    </nav>
    <!-- <nav class="navbar navbar-expand-md navbar-dark mb-3 margin-top-10">
      <div class="container justify-content-start">
        <MemberLogin/>
        <MemberRegister/>
      </div>
    </nav> -->
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue';
import TemplateTopNav from './TemplateTopNav';

export default {
  name: 'TopNavTheme0',
  mixins: [TemplateTopNav],
  components: {
    // NavMenu: () => import('@/components/menu/NavMenu'),
    // ContentWrapper: () => import('@/components/content/ContentWrapper')
    // MemberLogin: () => import('@/components/atoms/MemberLogin'),
    // MemberRegister: () => import('@/components/atoms/MemberRegister'),
    SelectLanguage: () => import('@/components/atoms/SelectLanguage'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
};
</script>

<style scoped>
.margin-top-10{
  margin-top: 4rem;
}
@media (max-width: 990px) {
  .btn-topVacation {
    display: none;
  }
  .btnDomestic {
    width: 99px;
    max-width: 100px;
    padding: 10px;
    line-height: 14px;
    white-space: pre-wrap;
  }
}

@media (max-width: 767px) {
  .margin-top-10{
    margin-top: 0;
  }
}
@media (max-width: 470px) {
  .mt-10 {
    margin-top: -10px;
  }
  .displayLeft {
    float: left;
  }
}
.top-nav .marketer_logo {
  max-height: 105px;
  margin-left: 7rem;
}

.top-nav .mobile_logo .marketer_logo {
    max-width: 100%;
    max-height: 80px;
}

.list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
}
.list-inline {
  padding-right: 0;
}

.top-nav .menuarea .mobile_logo {
  margin-right: 10px;
}
</style>
